'use strict';

var atrack;

/**
 * Initialize the GDDL analytics tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

// const getLinkCategory = (url) => {
//     if (!(url.substring(0, 10).includes('//'))) return 'internal-link'; // Relative link => internal
//     return new URL(url, document.baseURI).host === window.location.host ? 'internal-link' : 'external-link'; // Check if the host is the same as the current page
// };

// const getElementType = (element) => {
//     if ($(element).find('i:is(.fa, .fas, .far, .fal, .fab)').length // Font Awesome => icon
//         || $(element).find('svg').length // Feather or other svg icon => icon
//         || $(element).find('img[src$=".svg"]').length) { // SVG image => icon
//         return 'icon';
//     } if ($(element).find('img').length) { // Any other image => image
//         return 'image';
//     }
//     return 'text'; // Default to text
// };

// const getElementPlacement = (element) => {
//     let placement = $('.page').data('action');
//     if ($(element).closest('[data-placement]').length) {
//         placement += '/' + $(element).closest('[data-placement]').data('placement');
//     }
//     return placement;
// };

const createEventData = (name, text, position, url, category, type, placement) => {
    const eventData = {
        event: 'link-click',
        info: {
            name: name,
            text: text,
            category: category,
            type: type,
            position: position,
            placement: placement,
            target_url: url
        }
    };

    atrack.trackEvent(eventData);
};

// const getPosition = (element) => {
//     if ($(element).closest('.gddl-track-link-group').length) {
//         return $(element).closest('.gddl-track-link-group').find('a').index($(element)) + 1;
//     } if ($(element).closest('ol, ul').length) {
//         return $(element).closest('ol, ul').find('> li').index($(element).closest('li')) + 1;
//     } if ($(element).closest('.similar-products-list').length) {
//         return $(element).closest('.similar-products-list').find('a').index($(element)) + 1;
//     }
//     return '';
// };

// const getAbsoluteURL = (url) => {
//     return new URL(url, document.baseURI).href;
// };

// const getName = (element) => {
//     if ($(element).closest('.similar-products-list').length) return 'similar-product';
//     return $(element).data('name') || $(element).attr('title') || $(element).attr('aria-label') || $(element).text() || 'other';
// };

// const initLinkTracker = () => {
//     let linkSelector = '.gddl-track-link-group a'; // Track link groups
//     linkSelector += ', a.gddl-track-link'; // Track individual links
//     linkSelector += ', .similar-products-list > a.similar-products-item'; // We can't add .gddl-track-link to Verbilia so we have to track it here
//     linkSelector += ', a[href^="//"],a[href^="http"]:not([href*="//' + window.location.hostname + '"])'; // Also track all external links
//     linkSelector += ', a[href^="tel:"],a[href^="mailto:"]'; // Also track phone and email links
//     $(document).on('mousedown', linkSelector, function (e) {
//         if (e.which < 3) { // Only track left and middle mouse clicks. Also, jQuery normalizes e.which for us
//             const href = $(this).attr('href');
//             const name = getName(this);
//             const text = $(this).text().trim();
//             const position = getPosition(this);
//             const url = getAbsoluteURL(href);
//             const category = getLinkCategory(href);
//             const placement = getElementPlacement(this);

//             let type = '';
//             switch (new URL(href, document.baseURI).protocol) {
//                 case 'tel:':
//                     type = 'phone';
//                     break;
//                 case 'mailto:':
//                     type = 'email';
//                     break;
//                 default:
//                     type = getElementType(this);
//             }

//             createEventData(name, text, position, url, category, type, placement);
//         }
//     });
// };

/*
const initCollapseTracker = () => {
    $(document).on('click', '[data-toggle="collapse"]', function () {
        const name = $(this).attr('href').substring(1);
        const text = $(this).text().trim();
        const position = $(this).index('[data-toggle="collapse"]') + 1;
        const url = '';
        const category = 'interaction';
        const type = 'expand-collapse';
        const placement = getElementPlacement(this);
        createEventData(name, text, position, url, category, type, placement);
    });
};
*/

const initReviewsTracker = () => {
    $('body').on('click', '.js-open-reviews', () => {
        createEventData('show-reviews', '', '1', '', 'interaction', 'icon', 'pdp');
    });
};

const init = () => {
    initAnalyticsTracker();
    initReviewsTracker();

    // TODO: fix bugged link analytics
    // initLinkTracker();

    // We don't want to track collapse events via the link tracker, but we might want to track them in the future
    // initCollapseTracker();
};

$(() => {
    init();
});
